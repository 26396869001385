<template>
  <div class="container">
    <!-- <div class="header">
      <div class="title">
        <img src="../../assets/images/assess.png" alt />
        <span>企业自评自测</span>
      </div>
      <span class="back" @click="$router.push('/home')">返回首页</span>
      <span class="login-out" @click="loginUp">退出登录</span>
    </div>-->
    <div class="main">
      <div class="diagnose">
        <el-button type="primary" @click="toAnswer" class="answerBtn">
          <img src="../../assets/images/answer.png" />
          继续答题
        </el-button>
        <el-dropdown @command="handleCommand">
          <el-button type="primary">
            新建自诊断
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">制造业</el-dropdown-item>
            <el-dropdown-item command="1">服务业</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="null" v-if="showNull">
        <img src="../../assets/evaluation/null.png" />
        <span>空空如也，快去新建自诊断吧~</span>
      </div>
      <div class="tableBox" v-else>
        <div class="tableHead">
          <span>名称</span>
          <span>提交企业</span>
          <span>星级</span>
          <span>总得分</span>
          <span>特征描述</span>
          <span>提交状态</span>
          <span>操作</span>
        </div>
        <div class="tableBody">
          <div class="tableRow" v-for="(item,index) in historyList" :key="index">
            <div class="result">
              <div>企业诊断结果</div>
              <div>提交时间:{{item.saveTime | time}}</div>
            </div>
            <div>{{item.companyName}}</div>
            <div>
              <el-rate disabled :v-model="item.level?item.level:0"></el-rate>
            </div>
            <div>
              <div class="grade">{{item.evaluateSorce}}分</div>
            </div>
            <div>{{ item.remark }}</div>
            <div>
              <span v-if="item.evaluateTag" style="color: #0055FF">已提交</span>
              <span v-else>未提交（请在继续答题提交）</span>
            </div>
            <div @click="handleDelete(item)">
              <img src="../../assets/images/delete.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, del } from "../../api/http";
export default {
  data() {
    return {
      showNull: false,
      historyList: [],
      compId: JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId,
      companyName: "",
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.replace("T", " ");
    },
  },
  created() {
    this.getHistory();
    this.getCompany();
  },
  methods: {
    loginUp() {
      window.sessionStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      this.$message({
        type: "success",
        message: "退出成功",
      });
    },
    getHistory() {
      get("/api/EvaluateMain?compId=" + this.compId).then((resp) => {
        if (resp.code == 200) {
          if (resp.count == 0) {
            this.showNull = true;
          } else {
            this.historyList = resp.data;
          }
        }
      });
    },
    getCompany() {
      this.$store.state.companyUserList.forEach((item) => {
        if (item.id == this.compId) {
          this.companyName = item.companyName;
        }
      });
    },
    handleCommand(command) {
      // 判断是否有未完成的题目
      get("/api/EvaluateMain/GetIsExistUndo?compId=" + this.compId).then(
        (res) => {
          if (res.data) {
            let queryParams = {
              id: 0,
              companyName: this.companyName,
              companyId: this.compId,
              saveUserName: JSON.parse(sessionStorage.getItem("userInfo"))
                .userName,
              saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id,
              saveTime: new Date().toISOString(),
              evaluateTag: 0,
              evaluateSorce: 0,
              remark: "",
              level: "",
              tenantId: JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
              evaluateTypr: 0,
            };
            post("/api/EvaluateMain?type=" + command, queryParams).then(
              (res) => {
                if (res.code == 200) {
                  get(
                    "/api/EvaluateMain/GetCompanyIsUndo?compId=" + this.compId
                  ).then((res) => {
                    // this.$router.push("/answer?mainId=" + res.data.id);
                    this.$router.push({
                      path: "/answer",
                      query: {
                        params: JSON.stringify(res.data),
                      },
                    });
                  });
                }
              }
            );
          } else {
            this.$message({
              message: "您还有未完成的题目，请先完成！",
              type: "warning",
            });
            return;
          }
        }
      );
    },
    toAnswer() {
      if (this.historyList.length == 0) {
        this.$message.warning("没有未完成的答题，请新建自诊断");
        return;
      }
      if (this.historyList[0].evaluateTag == 0) {
        this.$router.push({
          path: "/answer",
          query: {
            params: JSON.stringify(this.historyList[0]),
          },
        });
      } else {
        this.$message.warning("没有未完成的答题，请新建自诊断");
      }
    },
    // 删除
    handleDelete(item) {
      item.level = item.level + "";
      this.$confirm("此操作将永久删除该自评自测, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del("/api/EvaluateMain/" + item.id, item).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getHistory();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: PingFang SC;
  src: url("../../assets/font/PingFang-SC.ttf");
}
.container {
  .header {
    width: 100%;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 4px 1px 0px rgba(181, 181, 181, 0.23);
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    .title {
      width: 258px;
      height: 100%;
      border-right: 3px solid #f6f8f9;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: bold;
        font-size: 22px;
        color: #1b1e20;
        margin-left: 8px;
      }
    }
    .back {
      font-weight: bold;
      font-size: 18px;
      color: #4985ff;
      margin-left: 24px;
    }
    .login-out {
      position: absolute;
      right: 30px;
      padding: 0 5px;
      border-left: 2px solid #c0c0c07b;
      border-right: 2px solid #c0c0c07b;
      font-weight: 500;
      font-size: 18px;
      color: #2c7aff;
    }
  }
  .main {
    // padding: 24px 35px 0;
    .diagnose {
      .answerBtn {
        margin-right: 10px;
        img {
          vertical-align: text-top;
          width: 13px;
          height: 14px;
        }
      }
    }
    .tableBox {
      margin-top: 14px;
      .tableHead {
        width: 100%;
        height: 56px;
        background: #f8f9fd;
        line-height: 56px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #646870;
        margin-bottom: 3px;
        padding-left: 36px;
        box-sizing: border-box;
        display: flex;
        & > span:nth-child(1) {
          width: 18%;
        }
        & > span:nth-child(2) {
          width: 23%;
        }
        & > span:nth-child(3) {
          width: 14%;
        }
        & > span:nth-child(4) {
          width: 12%;
        }
        & > span:nth-child(5) {
          width: 10%;
        }
        & > span:nth-child(6) {
          width: 17%;
        }
        & > span:nth-child(7) {
          width: 6%;
        }
      }
      .tableBody {
        .tableRow {
          padding-left: 36px;
          height: 82px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(64, 65, 73, 0.18);
          border-radius: 4px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          & > div:nth-child(1) {
            width: 18%;
          }
          & > div:nth-child(2) {
            width: 23%;
          }
          & > div:nth-child(3) {
            width: 14%;
          }
          & > div:nth-child(4) {
            width: 12%;
          }
          & > div:nth-child(5) {
            width: 10%;
          }
          & > div:nth-child(6) {
            width: 17%;
          }
          & > div:nth-child(7) {
            width: 6%;
          }
          .result {
            & > div:nth-child(1) {
              font-size: 18px;
            }
            & > div:nth-child(2) {
              color: #646870;
            }
          }
          .grade {
            width: 95px;
            height: 34px;
            background: #ef7c7b;
            border-radius: 17px;
            border: 1px solid #c05554;
            text-align: center;
            line-height: 34px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
    .null {
      text-align: center;
      img {
        width: 380px;
        display: block;
        margin: 100px auto 30px auto;
      }
    }
  }
}
</style>